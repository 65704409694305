.apexcharts-tooltip {
  z-index: 10 !important;
  position: absolute;
}

.tooltip {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 3px;
  color: black;
  
  .tooltip-row {
    display: flex;
    flex-direction: row;

    div {
      margin-left: 3px;
    }
  }
}

.my-tooltip {
  width: 100px;
  height: 100px;
  position: absolute;
}

// .MuiTooltip-tooltip {
//   padding: 0 !important;
// }
