// .rdrCalendarWrapper {
//   background-color: #1f1f1f !important;
//   border-radius: 14px;

//   .rdrDayNumber span {
//     color: #f7f3f0;
//   }

//   .rdrDayPassive span {
//     color: #64605d !important;
//     display: none;
//   }

//   .rdrMonthPicker select {
//     color: #f7f3f0;
//   }
//   .rdrYearPicker select {
//     color: #f7f3f0;
//   }

//   .rdrNextPrevButton {
//     background: none;
//   }

//   .rdrPprevButton i {
//     border-color: transparent #f5f4f4 transparent transparent;
//     width: 18px;
//     height: 10px;
//   }

//   .rdrNextButton i {
//     border-color: transparent transparent transparent #f5f4f4;
//     width: 18px;
//     height: 10px;
//   }

//   .rdrMonthPicker select {
//     color: #f5f4f4;
//     font-weight: 400;
//     font-size: 14px;
//   }

//   .rdrYearPicker select {
//     color: #f5f4f4;
//     font-weight: 400;
//     font-size: 14px;
//   }

//   .rdrWeekDays span {
//     color: #656565;
//   }

//   .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
//     color: #bc6d29;
//   }

//   .rdrSelected,
//   .rdrInRange,
//   .rdrStartEdge,
//   .rdrEndEdge span {
//     background: none;
//   }
// }

// .rdrDayDisabled {
//   background-color: #1f1f1f !important;
//   span {
//     color: #64605d !important;
//   }
// }

// .rdrMonthAndYearPickers {
//   justify-content: space-between;
// }

// .rdrMonthAndYearWrapper {
//   padding-bottom: 18px;
// }

// .rdrDayHovered span {
//   background: none;
// }

// DataGridFilter Styles
// .date-picker-wrapper {
.rdrCalendarWrapper {
  background-color: #201b20 !important;
  border-radius: 14px;

  //Range Properties
  .rdrDateDisplayWrapper {
    background-color: #201b20 !important;
  }

  .rdrDateDisplayItem {
    background-color: #201b20 !important;
  }

  .rdrDateDisplayItemActive input {
    color: #f7f3f0;
  }

  .rdrInRange {
    color: #bc6d29 !important;
  }

  .rdrStartEdge {
    color: #bc6d29 !important;
  }

  .rdrEndEdge {
    color: #bc6d29 !important;
  }

  //Date
  .rdrDayNumber span {
    color: #f7f3f0;
  }

  .rdrDayPassive span {
    color: #64605d !important;
    display: none;
  }

  .rdrMonthPicker select {
    color: #f7f3f0;
  }
  .rdrYearPicker select {
    color: #f7f3f0;
  }

  .rdrNextPrevButton {
    background: none;
  }

  .rdrPprevButton i {
    border-color: transparent #f5f4f4 transparent transparent;
    width: 18px;
    height: 10px;
  }

  .rdrNextButton i {
    border-color: transparent transparent transparent #f5f4f4;
    width: 18px;
    height: 10px;
  }

  .rdrMonthPicker select {
    color: #f5f4f4;
    font-weight: 400;
    font-size: 14px;
  }

  .rdrYearPicker select {
    color: #f5f4f4;
    font-weight: 400;
    font-size: 14px;
  }

  .rdrWeekDays span {
    color: #656565;
  }

  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: #bc6d29;
  }

  .rdrSelected {
    background: none;
  }

  // .rdrSelected,
  // .rdrInRange,
  // .rdrStartEdge,
  // .rdrEndEdge span {
  //   background: none;
  // }

  // .rdrDayHovered span {
  //     background: none;
  //   }

  .rdrDayDisabled {
    background-color: #201b20 !important;
    span {
      color: #64605d !important;
    }
  }

  .rdrMonthAndYearPickers {
    justify-content: space-between;
  }

  .rdrMonthAndYearWrapper {
    padding-bottom: 18px;
  }
}
// }

.coach-date-picker-wrapper {
  .rdrCalendarWrapper {
    background-color: #201b20 !important;
    border-radius: 14px;
  
    //Range Properties
    .rdrDateDisplayWrapper {
      background-color: #201b20 !important;
    }
  
    .rdrDateDisplayItem {
      background-color: #201b20 !important;
    }
  
    .rdrDateDisplayItemActive input {
      color: #f7f3f0;
    }
  
    .rdrInRange {
      color: #DD4F4A !important;
    }
  
    .rdrStartEdge {
      color: #DD4F4A !important;
    }
  
    .rdrEndEdge {
      color: #DD4F4A !important;
    }
  
    //Date
    .rdrDayNumber span {
      color: #f7f3f0;
    }
  
    .rdrDayPassive span {
      color: #64605d !important;
      display: none;
    }
  
    .rdrMonthPicker select {
      color: #f7f3f0;
    }
    .rdrYearPicker select {
      color: #f7f3f0;
    }
  
    .rdrNextPrevButton {
      background: none;
    }
  
    .rdrPprevButton i {
      border-color: transparent #f5f4f4 transparent transparent;
      width: 18px;
      height: 10px;
    }
  
    .rdrNextButton i {
      border-color: transparent transparent transparent #f5f4f4;
      width: 18px;
      height: 10px;
    }
  
    .rdrMonthPicker select {
      color: #f5f4f4;
      font-weight: 400;
      font-size: 14px;
    }
  
    .rdrYearPicker select {
      color: #f5f4f4;
      font-weight: 400;
      font-size: 14px;
    }
  
    .rdrWeekDays span {
      color: #656565;
    }
  
    .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
      color: #DD4F4A;
    }
  
    .rdrSelected {
      background: none;
    }
  
    // .rdrSelected,
    // .rdrInRange,
    // .rdrStartEdge,
    // .rdrEndEdge span {
    //   background: none;
    // }
  
    // .rdrDayHovered span {
    //     background: none;
    //   }
  
    .rdrDayDisabled {
      background-color: #201b20 !important;
      span {
        color: #64605d !important;
      }
    }
  
    .rdrMonthAndYearPickers {
      justify-content: space-between;
    }
  
    .rdrMonthAndYearWrapper {
      padding-bottom: 18px;
    }
  }
}