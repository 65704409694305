.role-box {
  width: 31.4375rem;
  border-radius: 0.25rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-bottom: 1.5rem;
  @media (max-width: 450px) {
    width: 18rem;
  }
}

#roleview_title {
  align-self: flex-start;
  margin-left: 8.4375rem;
  @media (max-width: 450px) {
    align-self: center;
    margin-left: 0;
  }
}

.svgfix {
  display: flex;
  height: 15px;
  width: 18px;
}

.button_role-box {
  width: 29.4375rem;
  // margin-bottom:'1rem'
  @media (max-width: 450px) {
    width: 15rem;
  }
}
