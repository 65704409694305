/* Default font sizes */
.event-text {
  text-align: center;
  font-family: "DejaVu Sans";
}

.tss-label {
  color: #64605d;
}

.tss-value {
  color: #bc6d29;
}

.distance {
  color: #e3dfdc;
  margin-bottom: 0.5625rem;
}

.total-time {
  color: #e3dfdc;
  margin-top: 0.9375rem;
}

/* Font sizes for larger screens */
@media (min-width: 1840px) {
  .tss-value {
    font-size: 0.9375rem;
  }

  .tss-label {
    font-size: 0.9375rem;
    margin-top: 1.350rem;
  }

  .distance {
    font-size: 0.75rem;
  }

  .total-time {
    font-size: 0.75rem;
    margin-top: 2.1875rem;
  }
}
@media (max-width: 1350px) {
  .distance {
    font-size: 0.6rem;
  }

  .total-time {
    font-size: 0.6rem;
  }
}
